import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import VideoUpload from './VideoUpload';
import OnboardingForm from './OnboardingForm';


const API_BASE_URL = 'http://localhost:8000';

function App() {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const savedToken = sessionStorage.getItem('authToken');
        if (savedToken) {
            try {
                const decoded = JSON.parse(atob(savedToken.split('.')[1]));
                setUser(decoded);
                setToken(savedToken);
            } catch (e) {
                console.error('Error decoding token:', e);
                sessionStorage.removeItem('authToken');
            }
        }
        setLoading(false);
    }, []);

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/auth/google`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: credentialResponse.credential
                })
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.detail || 'Login failed');

            const authToken = data.access_token || data.token;
            setUser(data.user || data);
            setToken(authToken);
            sessionStorage.setItem('authToken', authToken);
        } catch (error) {
            console.error('Login Error:', error);
            alert('Login failed: ' + error.message);
        }
    };

    const handleLogout = () => {
        setUser(null);
        setToken(null);
        sessionStorage.removeItem('authToken');
    };

    if (loading) {
        return (
            <div className="min-h-screen bg-gray-100 flex items-center justify-center">
                <div className="text-lg">Loading...</div>
            </div>
        );
    }

    return (
        <Router>
            <div className="min-h-screen bg-gray-100">
                {user && (
                    <nav className="bg-white shadow-lg">
                        <div className="max-w-6xl mx-auto px-4">
                            <div className="flex justify-between items-center h-16">
                                <div className="text-xl font-semibold text-yellow-600">PixelProbe</div>
                                <div className="flex items-center space-x-4">
                                    {user.picture && (
                                        <img
                                            src={user.picture}
                                            alt="Profile"
                                            className="w-8 h-8 rounded-full"
                                        />
                                    )}
                                    <span>{user.name}</span>
                                    <button
                                        onClick={handleLogout}
                                        className="bg-yellow-600 text-white px-4 py-2 rounded hover:bg-yellow-700"
                                    >
                                        Logout
                                    </button>
                                </div>
                            </div>
                        </div>
                    </nav>
                )}

                <Routes>
                  <Route path="/" element={!user ? <LandingPage onGoogleSuccess={handleGoogleSuccess} user={user} /> : <Navigate to="/onboarding" />} />
                  <Route path="/onboarding" element={user ? <OnboardingForm /> : <Navigate to="/" />} />
                  <Route path="/playground" element={user ? <VideoUpload token={token} /> : <Navigate to="/" />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;