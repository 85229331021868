import React, { useState, useEffect, useRef } from 'react';
import { Play, Pause, RotateCcw, Shield, Upload, AlertCircle, Clock } from 'lucide-react';

const API_BASE_URL = 'http://localhost:8000';

const VideoUpload = ({ token }) => {
  const [file, setFile] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [status, setStatus] = useState(null);
  const [processingError, setProcessingError] = useState(null);
  const [processedVideoUrl, setProcessedVideoUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const videoRef = useRef(null);

  const StatusIndicator = ({ status }) => {
    const getStatusColor = () => {
      switch (status) {
        case 'pending': return 'text-yellow-500';
        case 'processing': return 'text-blue-500';
        case 'completed': return 'text-green-500';
        case 'failed': return 'text-red-500';
        default: return 'text-gray-500';
      }
    };

    const getStatusIcon = () => {
      switch (status) {
        case 'pending':
          return <Clock className="h-5 w-5 animate-pulse" />;
        case 'processing':
          return <div className="animate-spin rounded-full h-5 w-5 border-2 border-current border-t-transparent" />;
        case 'completed':
          return <Shield className="h-5 w-5" />;
        case 'failed':
          return <AlertCircle className="h-5 w-5" />;
        default:
          return null;
      }
    };

    return (
      <div className={`flex items-center gap-2 ${getStatusColor()}`}>
        {getStatusIcon()}
        <span className="font-medium capitalize">{status}</span>
      </div>
    );
  };

  // Poll for status when we have a videoId
  useEffect(() => {
    let intervalId;

    if (videoId && (status === 'pending' || status === 'processing')) {
      intervalId = setInterval(async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/api/videos/status/${videoId}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            throw new Error(`Status check failed: ${response.statusText}`);
          }

          const data = await response.json();
          console.log('Status update:', data); // For debugging

          setStatus(data.status);

          if (data.status === 'completed') {
            setProcessedVideoUrl(`${API_BASE_URL}/api/videos/play/${videoId}`);
            clearInterval(intervalId);
          } else if (data.status === 'failed') {
            setProcessingError(data.error);
            clearInterval(intervalId);
          }
        } catch (error) {
          console.error('Error checking status:', error);
          setProcessingError('Failed to check processing status');
          clearInterval(intervalId);
        }
      }, 2000);
    }

    return () => clearInterval(intervalId);
  }, [videoId, status, token]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (!selectedFile.type.startsWith('video/')) {
        setProcessingError('Please select a video file');
        return;
      }
      setFile(selectedFile);
      setProcessingError(null);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      setStatus('uploading');
      setUploadProgress(0);

      const response = await fetch(`${API_BASE_URL}/api/videos/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Upload failed: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Upload response:', data); // For debugging
      setVideoId(data.video_id);
      setStatus(data.status);
    } catch (error) {
      console.error('Upload error:', error);
      setProcessingError(error.message || 'Failed to upload video');
      setStatus('failed');
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="bg-white rounded-xl shadow-lg">
        <div className="border-b p-6">
          <h2 className="text-2xl font-bold text-gray-900">Video PII Redaction</h2>
          <p className="mt-2 text-gray-600">Upload your video to automatically detect and redact sensitive information</p>
        </div>

        <div className="p-6 space-y-6">
          {/* File Upload Section */}
          <div className="space-y-4">
            <input
              type="file"
              accept="video/*"
              onChange={handleFileChange}
              className="hidden"
              id="video-upload"
            />

            {!processedVideoUrl && (
              <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
                <label htmlFor="video-upload" className="cursor-pointer">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <span className="mt-2 block text-sm font-medium text-gray-600">
                    {file ? file.name : 'Select a video file'}
                  </span>
                </label>
                {file && !videoId && (
                  <button
                    onClick={handleUpload}
                    className="mt-4 bg-yellow-600 text-white px-4 py-2 rounded-lg hover:bg-yellow-700"
                  >
                    Start Processing
                  </button>
                )}
              </div>
            )}

            {/* Status Section */}
            {status && (
              <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                <StatusIndicator status={status} />
                {processingError && (
                  <p className="text-sm text-red-600">{processingError}</p>
                )}
                {status === 'processing' && (
                  <p className="text-sm text-gray-600">
                    Processing your video... This may take a few minutes.
                  </p>
                )}
              </div>
            )}

            {/* Processed Video Player */}
            {processedVideoUrl && (
              <div className="space-y-4">
                <h3 className="text-lg font-medium text-gray-900">Processed Video</h3>
                <div className="aspect-w-16 aspect-h-9">
                  <video
                    ref={videoRef}
                    controls
                    className="rounded-lg w-full"
                    src={processedVideoUrl}
                  />
                </div>
                <a
                  href={processedVideoUrl}
                  download
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700"
                >
                  Download Processed Video
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoUpload;