import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { AlignJustify, X } from 'lucide-react';

const LandingPage = ({ onGoogleSuccess, user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);

  const handleDemoClick = (e) => {
    e.preventDefault();
    if (!user) {
      setShowAuthPrompt(true);
    } else {
      window.location.href = '/playground';
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-blue-50 to-indigo-50">
      {/* Navigation */}
      <nav className="bg-white/90 backdrop-blur-sm border-b border-blue-100 fixed w-full z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <a href="/" className="text-2xl font-serif font-medium">
              <span className="text-slate-900">Pixel</span>
              <span className="text-blue-600">Probe</span>
            </a>

            <div className="hidden md:flex items-center space-x-4">
              <a href="#features" className="text-slate-600 hover:text-indigo-600 transition-colors font-medium">
                Redact in 3 Simple Steps
              </a>
              <button
              disabled
                onClick={handleDemoClick}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all shadow-sm font-medium"
              >
                Try Demo
              </button>
              <div className="pl-2">
                <button
                disabled
                  onClick={() => setShowAuthPrompt(true)}
                  className="flex items-center px-4 py-2 border-2 border-slate-200 rounded-lg hover:border-indigo-500 transition-all text-slate-700 hover:text-indigo-600 font-medium"
                >
                  Sign In
                </button>
              </div>
            </div>

            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden text-slate-600 hover:text-indigo-600 transition-colors"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <AlignJustify className="h-6 w-6" />}
            </button>
          </div>
        </div>

        <div className={`md:hidden transform transition-all duration-300 ${isMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}>
          <div className="bg-white border-t border-blue-100 py-2 space-y-2 px-4">
            <a
              href="#features"
              className="block py-2 text-slate-600 hover:text-indigo-600 transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              Redact in 3 Simple Steps
            </a>
            <button
            disabled
              onClick={handleDemoClick}
              className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all"
            >
              Try Demo
            </button>
            <button
            disabled
              onClick={() => {
                setShowAuthPrompt(true);
                setIsMenuOpen(false);
              }}
              className="w-full py-2 border-2 border-slate-200 rounded-lg hover:border-indigo-500 transition-all text-slate-700 hover:text-indigo-600"
            >
              Sign In
            </button>
          </div>
        </div>
      </nav>

      {/* Auth Modal */}
      {showAuthPrompt && (
        <div className="fixed inset-0 bg-slate-900/40 backdrop-blur-md z-50 flex items-center justify-center animate-fade-in">
          <div className="relative bg-gradient-to-br from-white to-indigo-50/50 rounded-xl shadow-2xl max-w-md w-full mx-4 transform animate-scale-in">
            <div className="p-8">
              <button
                onClick={() => setShowAuthPrompt(false)}
                className="absolute top-4 right-4 text-slate-500 hover:text-indigo-600 transition"
                aria-label="Close modal"
              >
                <X className="h-5 w-5" />
              </button>
              <div className="text-center space-y-6">
                <h3 className="text-2xl font-semibold text-slate-900">
                  Welcome to PixelProbe
                </h3>
                <p className="text-slate-600">
                  Sign in to access advanced video redaction features
                </p>
                <div className="flex justify-center pt-2">
                  <div className="w-full max-w-xs">
                    <GoogleLogin
                      onSuccess={onGoogleSuccess}
                      onError={() => console.log('Login Failed')}
                      shape="rectangular"
                      theme="filled_black"
                      size="large"
                      width="300"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Hero section */}
      <section className="relative pt-32 pb-24 lg:pb-32 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -top-40 -right-32 w-96 h-96 bg-blue-200 rounded-full opacity-20 blur-3xl" />
          <div className="absolute -bottom-40 -left-32 w-96 h-96 bg-indigo-300 rounded-full opacity-20 blur-3xl" />
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="flex flex-col items-center justify-center min-h-[60vh] text-center space-y-8">
            <div className="space-y-4">
              <div className="inline-block px-3 py-1 bg-gradient-to-r from-blue-500/10 to-indigo-500/10 border border-indigo-600/30 text-indigo-600 rounded-full text-xs font-medium">
                AI-powered
              </div>
              <h1 className="font-serif text-5xl md:text-6xl lg:text-7xl font-medium leading-tight tracking-tight text-slate-900">
                Privacy Protection
                <br />
                for Your Videos
              </h1>
            </div>
            <p className="text-xl md:text-2xl lg:text-3xl text-slate-600 max-w-2xl">
              Automatically detect and redact PII in videos with precision and control
            </p>
            <button
            disabled
              onClick={handleDemoClick}
              className="px-6 py-3 md:px-8 md:py-4 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-lg hover:from-blue-700 hover:to-indigo-700 transition-all shadow-lg hover:shadow-xl font-medium text-lg md:text-xl transform hover:-translate-y-0.5"
            >
              Try Demo
            </button>
          </div>
        </div>
      </section>

      {/* Features section */}
      <section id="features" className="py-24 bg-gradient-to-br from-slate-50 via-white to-indigo-50/10">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-20">
            <h2 className="text-4xl font-serif font-medium text-slate-900">
              Intelligent Video Redaction in Three Steps
            </h2>
          </div>

          <div className="space-y-16">
            {[
              {
                step: 1,
                title: "AI-Powered Detection",
                description: "Automatically identify and flag sensitive information in your videos with precision.",
                image: "/redact1.svg"
              },
              {
                step: 2,
                title: "Precision Control",
                description: "Adjust redaction boundaries for complete control over redaction.",
                image: "/redact2.svg"
              },
              {
                step: 3,
                title: "Redact while tracking",
                description: "Ensure seamless redaction with consistent tracking throughout.",
                image: "/redact3.svg"
              }
            ].map(({ step, title, description, image }, index) => (
              <div key={step}
                   className={`group bg-gradient-to-br from-white to-blue-50/30 rounded-2xl shadow-sm ring-1 ring-slate-100 p-8 transition-all duration-300 hover:shadow-xl
                              ${index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'}
                              flex flex-col lg:flex-row items-center gap-12`}
              >
                <div className="relative w-full lg:w-1/2">
                  <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <img
                    src={image}
                    alt={title}
                    className="w-full h-auto relative z-10"
                  />
                </div>
                <div className="w-full lg:w-1/2 space-y-6">
                  <div className="flex items-center space-x-4">
                    <div className="w-10 h-10 rounded-full bg-gradient-to-br from-blue-50 to-indigo-50 flex items-center justify-center">
                      <span className="text-indigo-700 font-medium text-lg">{step}</span>
                    </div>
                    <h3 className="text-2xl font-medium text-slate-900">{title}</h3>
                  </div>
                  <p className="text-slate-600 text-lg leading-relaxed">{description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-slate-900 to-indigo-900 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center justify-center space-y-4">
            <a href="/" className="text-2xl font-serif font-medium text-white">
              PixelProbe
            </a>
            <p className="text-slate-400 text-sm">
              &copy; {new Date().getFullYear()} PixelProbe. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;