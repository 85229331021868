import React, {
    useState
} from 'react';
import {
    ChevronLeft,
    ChevronRight
} from 'lucide-react';

const OnboardingForm = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        useCase: '',
        redactionNeeds: [], // For storing multiple selections
        followUpResponse: '',
        selectedChallenges: [] // For third question
    });

    const questions = [{
            step: 1,
            title: "What describes your video redaction needs?",
            type: "radio",
            options: [{
                    label: "I handle sensitive customer/client videos regularly",
                    value: "customer_videos",
                    followUp: {
                        title: "What types of videos do you handle?",
                        type: "text",
                        placeholder: "E.g. customer support calls, surveillance footage, marketing content, interviews"
                    }
                },
                {
                    label: "I need to comply with privacy regulations",
                    value: "privacy_compliance",
                    followUp: {
                        title: "Which regulation is most relevant to your work?",
                        type: "text",
                        placeholder: "E.g. GDPR, HIPAA, CCPA"
                    }
                },
                {
                    label: "I create content requiring face/identity protection",
                    value: "content_protection"
                },
                {
                    label: "I manage video evidence/documentation with PII",
                    value: "evidence_pii"
                },
                {
                    label: "I'm evaluating automated redaction solutions",
                    value: "evaluating"
                },
                {
                    label: "Other",
                    value: "other",
                    followUp: {
                        title: "Please specify your needs",
                        type: "text",
                        placeholder: "Tell us more about your use case"
                    }
                }
            ]
        },


        {
            step: 2,
            title: "How many videos do you need to redact each month?",
            subtitle: "This helps us recommend the right solution for your needs",
            type: "radio",
            options: [{
                    label: "1-5 videos per month",
                    value: "low",
                    followUp: {
                        title: "Average video duration?",
                        placeholder: "E.g. 15 minutes, 1 hour etc."
                    }
                },
                {
                    label: "6-20 videos per month",
                    value: "medium",
                    followUp: {
                        title: "Average video duration?",
                        placeholder: "E.g. 15 minutes, 1 hour etc."
                    }
                },
                {
                    label: "21-50 videos per month",
                    value: "high",
                    followUp: {
                        title: "Average video duration?",
                        placeholder: "E.g. 15 minutes, 1 hour etc."
                    }
                },
                {
                    label: "50+ videos per month",
                    value: "enterprise",
                    followUp: {
                        title: "Average video duration?",
                        placeholder: "E.g. 15 minutes, 1 hour etc."
                    }
                },
                {
                    label: "Not sure yet",
                    value: "unknown",
                }
            ]
        },

        {
            step: 3,
            title: "What's your biggest challenge with redaction?",
            subtitle: "Select all that apply",
            type: "select",
            options: [{
                    label: "Time-consuming manual work",
                    value: "manual_effort",
                },
                {
                    label: "High costs of current solutions",
                    value: "cost",
                },
                {
                    label: "Poor accuracy and quality",
                    value: "quality",
                },
                {
                    label: "Hard to handle high volumes",
                    value: "scale",
                },
                {
                    label: "Other",
                    value: "other",
                    followUp: {
                        title: "Please specify your challenge",
                        placeholder: ""
                    }
                }
            ]
        },

        {
            step: 4,
            title: "Which industry best describes your organization?",
            type: "radio",
            options: [{
                    label: "Healthcare & Medical",
                    value: "healthcare"
                },
                {
                    label: "Legal & Law Enforcement",
                    value: "legal"
                },
                {
                    label: "Media & Entertainment",
                    value: "media"
                },
                {
                    label: "Education & Research",
                    value: "education"
                },
                {
                    label: "Financial Services",
                    value: "finance"
                },
                {
                    label: "Government & Public Sector",
                    value: "government"
                },
                {
                    label: "Other",
                    value: "other",
                    followUp: {
                        title: "Please specify your industry",
                        placeholder: "E.g. Manufacturing, Retail, Technology"
                    }
                }
            ]
        },


        {
            step: 5,
            title: "What's your monthly budget for video redaction?",
            subtitle: "This helps us recommend suitable solutions",
            type: "radio",
            options: [{
                    label: "Under $100/month",
                    value: "tier_1"
                },
                {
                    label: "$100-$500/month",
                    value: "tier_2"
                },
                {
                    label: "Over $500/month",
                    value: "tier_3"
                },
                {
                    label: "Don't know yet",
                    value: "undecided",
                    followUp: {
                        title: "Expected budget range?",
                        placeholder: "Any rough estimate helps us serve you better"
                    }
                }
            ]
        }
    ];

    const currentQuestion = questions[step - 1];

    const handleNext = () => {
        if (step < questions.length) {
            setStep(step + 1);
        }
    };

    const handlePrevious = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleFollowUpChange = (e) => {
        setFormData({
            ...formData,
            followUpResponse: e.target.value
        });
    };

    const selectedOption = currentQuestion.options?.find(opt => opt.value === formData.useCase);

    const handleSelect = (option) => {
        if (currentQuestion.type === 'select') {
            setFormData(prev => {
                const updated = prev.selectedChallenges.includes(option.value) ?
                    prev.selectedChallenges.filter(v => v !== option.value) :
                    [...prev.selectedChallenges, option.value];
                return {
                    ...prev,
                    selectedChallenges: updated
                };
            });
        } else {
            setFormData({
                ...formData,
                useCase: option.value,
                followUpResponse: ''
            });
        }
    };

    return ( <
        div className = "min-h-screen bg-white" >
        <
        div className = "max-w-2xl mx-auto px-4 py-16" >
        <
        div className = "mb-8" >
        <
        div className = "flex items-center justify-between mb-2" >
        <
        span className = "text-sm text-gray-500" > {
            step
        }
        / {questions.length}</span >
        <
        div className = "flex gap-2" > {
            step > 1 && ( <
                button onClick = {
                    handlePrevious
                }
                className = "p-1 rounded-full hover:bg-gray-100" >
                <
                ChevronLeft className = "w-5 h-5" / >
                <
                /button>
            )
        } {
            step < questions.length && ( <
                button onClick = {
                    handleNext
                }
                className = "p-1 rounded-full hover:bg-gray-100" >
                <
                ChevronRight className = "w-5 h-5" / >
                <
                /button>
            )
        } <
        /div> <
        /div> <
        div className = "h-1 bg-gray-100 rounded-full" >
        <
        div className = "h-1 bg-yellow-600 rounded-full transition-all duration-300"
        style = {
            {
                width: `${(step / questions.length) * 100}%`
            }
        }
        /> <
        /div> <
        /div>

        <
        div className = "space-y-6" >
        <
        h1 className = "text-3xl font-bold text-gray-900" > {
            currentQuestion.title
        } < /h1> {
            currentQuestion.subtitle && ( <
                p className = "text-gray-500" > {
                    currentQuestion.subtitle
                } < /p>
            )
        }

        <
        div className = "space-y-3" > {
            currentQuestion.options.map((option, index) => ( <
                div key = {
                    index
                }
                className = "space-y-2" >
                <
                button onClick = {
                    () => handleSelect(option)
                }
                className = {
                    `w-full p-4 text-left border rounded-lg transition-all
                      ${currentQuestion.type === 'select'
                        ? formData.selectedChallenges.includes(option.value)
                          ? 'border-yellow-600 bg-yellow-50'
                          : 'border-gray-200 hover:border-yellow-600'
                        : formData.useCase === option.value
                          ? 'border-yellow-600 bg-yellow-50'
                          : 'border-gray-200 hover:border-yellow-600'}`
                } >
                {
                    option.label
                } <
                /button>

                {
                    option.followUp && formData.useCase === option.value && ( <
                        div className = "pl-4" >
                        <
                        label className = "block text-sm font-medium text-gray-700 mb-1" > {
                            option.followUp.title
                        } <
                        /label> <
                        input type = "text"
                        value = {
                            formData.followUpResponse
                        }
                        onChange = {
                            handleFollowUpChange
                        }
                        placeholder = {
                            option.followUp.placeholder
                        }
                        className = "w-full p-2 border border-gray-300 rounded-md focus:ring-yellow-500 focus:border-yellow-500" /
                        >
                        <
                        /div>
                    )
                } <
                /div>
            ))
        } <
        /div>

        <
        div className = "pt-6" > {
            step === questions.length ? ( <
                button className = "w-full bg-yellow-600 text-white py-3 px-4 rounded-lg hover:bg-yellow-700"
                onClick = {
                    () => {
                        console.log('Form submitted:', formData);
                        window.location.href = '/playground';
                    }
                } >
                Get Started <
                /button>
            ) : ( <
                button className = "w-full bg-yellow-600 text-white py-3 px-4 rounded-lg hover:bg-yellow-700"
                onClick = {
                    handleNext
                }
                disabled = {
                    !formData.useCase || (selectedOption?.followUp && !formData.followUpResponse)
                } >
                Continue <
                /button>
            )
        } <
        /div> <
        /div> <
        /div> <
        /div>
    );
};

export default OnboardingForm;